import React, { useState, useEffect, useRef } from "react";
import { ASSET_URL, AWS_API } from "../../../config";
import axios from "axios";
import { Spin } from "antd";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
export default function UploadImage({ onChange, onHide }) {
  const inputFile = useRef();
  const [loadingImage, setLoadingImage] = useState(false);
  const [image, setImage] = useState(null);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const upload = async (base64) => {
    var file = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    var signedUrl = await getSignedUrl(file.type);
    const upFile = await axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((err) => console.error(err));

    if (upFile.status == 200) {
      return {
        status: true,
        key: signedUrl.key,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  const getSignedUrl = async (mime) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    var signedUrl = null;
    await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
      .then((response) => response.json())
      .then((res) => (signedUrl = res))
      .catch((err) => console.error(err));
    return signedUrl;
  };

  const handleChangeFile = async (e) => {
    e.preventDefault();
    
    const files = e.target.files;
    if(files.length < 1){
        return;
    }
    setLoadingImage(true);
    for (var a = 0; a < files.length; a++) {
      const base64 = await toBase64(files[a]);
      setImage(base64);
      const upFile = await upload(base64);
      if (upFile.status) {
        const url = ASSET_URL + upFile.key;
        onChange(url);
        setLoadingImage(false);
      }
    }
  };

  return (
    <div style={{
        width: "fit-content",
        position: "relative"
    }}>
      <input
        style={{
          display: "none",
        }}
        onChange={handleChangeFile}
        ref={inputFile}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
      />
      <Spin spinning={loadingImage}>
        <div
          onClick={() => {
            inputFile.current.click();
          }}
          style={{
            width: 95,
            aspectRatio: "1/1",
            background: "rgba(0, 0, 0, 0.85)",
            borderRadius: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(255 255 255)",
            fontSize: 25,
            overflow: "hidden",
            opacity: loadingImage ? "0.5" : "1",
            position: "relative",
          }}
        >
          {!image ? (
            <BsFillCloudUploadFill />
          ) : (
            <>
              <img
                style={{
                  height: "100%",
                }}
                src={image}
              />
            </>
          )}
        </div>
      </Spin>
      {image && (
        <div
          onClick={() => {
            setImage(null);
            onChange(null);
            if(onHide){
                onHide(true)
            }
          }}
          style={{
            position: "absolute",
            right:0,
            top: 0,
            fontSize: 25,
            cursor: "pointer",
            color: "red",
            background: "#fff",
            height: "23px",
            width: "23px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            lineHeight: 0,
            padding: 0
          }}
        >
          <AiFillCloseCircle />
        </div>
      )}
    </div>
  );
}
