import React from "react";
import { connect } from "react-redux";
import {Redirect, Route} from "react-router-dom";
import styled from "styled-components";
import { Layout, Drawer, Row, Col, Spin } from "antd";
import { withWindowSizeListener } from "react-window-size-listener";
import LeftMenu from "../components/LeftMenu";
import { Helmet } from "react-helmet";
import UserMenu from "../components/UserMenu";
import SiteSelector from "../components/sites/SiteSelector";
import { history } from "../history";

const { Header, Sider, Content } = Layout;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
`;

const MOBILEWIDTH = 991;

class defaultLayout extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed } = this.state;
    const {
      windowSize,
      appInit,
      title,
      role,
      currentUser,
      site_selector,
    } = this.props;
    if (!appInit)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Spin /> <p>Loading...</p>
          </div>
        </div>
      );
    if (role) {
      if (!currentUser) {
        history.push("/login");
      }
      let canAccess = false;
      role.forEach((r) => {
        if (
          currentUser &&
          currentUser.roles.map((role) => role.name).includes(r)
        ) {
          canAccess = true;
        }
      });
      if (!canAccess) {
          return <Redirect to="/image-generation" />;
        // return <div>Access denied 1</div>;
      }
    }
    return (
      <Container>
        {this.props.title && (
          <Helmet>
            <title>{this.props.title} - PPOD</title>
          </Helmet>
        )}
        <Layout>
          <div
            style={{
              width: "100%",
              position: "relative",
              zIndex: 2,
              overflow: "hidden",
              display: "grid",
              gridTemplateRows: "60px auto",
            }}
          >
            <Header
              style={{
                background: "#FFF",
                color: "#ff9100",
                zIndex: 100,
                padding: 0,
                display: "flex",
                justifyItems: "center",
                boxShadow: "0 1px 6px 0 rgba(21,27,38,.15)",
              }}
            >
              <Row
                type="flex"
                style={{ width: "calc(100%)", paddingRight: 15 }}
              >
                <Col
                  span={16}
                  lg={20}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className={`${
                      collapsed ? "oppprint-menu collapsed" : "oppprint-menu"
                    }`}
                    onClick={this.toggle}
                  >
                    <img
                      src="/logo.png"
                      alt=""
                      style={{ padding: "5px 15px", width: 110 }}
                    />
                  </div>
                  {site_selector !== false && <SiteSelector />}
                </Col>
                <Col span={8} lg={4}>
                  <UserMenu />
                </Col>
              </Row>
            </Header>
            <div
              style={{
                display: `${
                  windowSize.windowWidth > MOBILEWIDTH ? "grid" : "unset"
                }`,
                gridTemplateColumns: `${collapsed ? "80px" : "220px"} auto`,
              }}
            >
              <div>
                {windowSize.windowWidth > MOBILEWIDTH ? (
                  <Sider
                    width={220}
                    style={{ height: "100%" }}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                  >
                    <LeftMenu />
                  </Sider>
                ) : (
                  <Drawer
                    headerStyle={{
                      padding: "7px 24px",
                      borderColor: "rgba(255, 255, 255, 0.25)",
                      height: "100%",
                    }}
                    drawerStyle={{
                      minHeight: "100%",
                      backgroundColor: "#151b26",
                    }}
                    bodyStyle={{
                      padding: 0,
                    }}
                    visible={collapsed}
                    placement="left"
                    closable={false}
                    maskClosable={true}
                    onClose={(e) => {
                      this.setState({
                        collapsed: false,
                      });
                    }}
                  >
                    <LeftMenu />
                  </Drawer>
                )}
              </div>
              <Content
                style={{
                  boxSizing: "border-box",
                  maxWidth: "100vw",
                  overflow: "hidden",
                  color: "#151b26",
                  /*minHeight: 'calc(100% - 117px)', maxHeight: 'calc(100% - 117px)',*/ borderTop:
                    "1px solid rgb(227, 233, 240)",
                  borderBottom: "1px solid rgb(227, 233, 240)",
                  display: "grid",
                  // gridTemplateRows: "auto 55px",
                  height: "calc(100vh - 60px)",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    // padding: "15px",
                    // margin:"15px",
                    // backgroundColor:"#fff"
                    padding: "15px",
                  }}
                >
                  <Route {...this.props} />
                </div>
                {/* <div>
                  <Layout.Footer
                    style={{
                      height: 50,
                      padding: "15px 15px",
                      textAlign: "left",
                    }}
                  >
                    PPOD ©2021
                  </Layout.Footer>
                </div> */}
              </Content>
            </div>
          </div>
        </Layout>
      </Container>
    );
  }
}

export default connect((state) => {
  return {
    appInit: state.app.appInit,
    currentUser: state.app.currentUser,
  };
})(withWindowSizeListener(defaultLayout));
